














































import { Component, Prop, Vue } from "vue-property-decorator"
import HouseMap from "./HouseMap.vue"
import House from "~/models/House"
import VisitHouseTimeline from "@/pages/visits/VisitHouseTimeline.vue"
import HouseCard from "./HouseCard/HouseCard.vue"

@Component({
  components: {
    VisitHouseTimeline,
    HouseCard,
    HouseMap,
  },
})
export default class HouseVisits extends Vue {
  @Prop({ required: true }) house!: House
  @Prop({ default: false }) loading!: boolean

  get visits() {
    return this.house ? this.house.visits : null
  }

  createVisit() {
    if (!this.house.id) throw "Cannot create visit without house.id"
    if (this.$route.name) {
      const url = this.$route.name.replace("visits", "visit")

      this.$router.push({
        name: url,
        params: {
          houseSearchId: this.$route.params.houseSearchId,
          visitId: "new",
          houseId: this.house.id.toString(),
        },
      })
    }
  }
}
