












































































































































































import { Component, Vue, Prop } from "vue-property-decorator"
import OcularAvatar from "~/components/OcularAvatar.vue"
import Visit from "~/models/Visit"
import { translate } from "@/store"
import OcularRating from "~/components/OcularRating/OcularRating.vue"
import { state } from "@/store"

const visitStatusConfig = {
  canceled: {
    color: "error",
    label: "Annulée",
  },
  planned: {
    label: "CR en attente",
    color: "warning",
  },
  done: {
    label: "CR effectué",
    color: "success",
  },
}

@Component({
  components: { OcularRating, OcularAvatar },
})
export default class VisitCard extends Vue {
  @Prop({ required: true }) visit!: Visit
  @Prop({ required: false, default: false }) isFirstElement!: boolean
  @Prop({ required: false, default: false }) isLastElement!: boolean
  expanded = false
  limitShortDescription = 500

  get visitType() {
    if (this.visit.visit_type)
      return translate("visit_type", this.visit.visit_type)
    return null
  }

  get isOwnVisit() {
    return (
      this.visit.owner.id === state.profile.id &&
      this.visit.deal_id === state.currentDealId()
    )
  }

  get visitStatus() {
    return this.visit.status
  }

  get visitStatusText() {
    if (this.visit.status) return visitStatusConfig[this.visit.status]?.label
    return null
  }

  get visitStatusColor() {
    return visitStatusConfig[this.visit.status]?.color
  }

  redirectToVisit() {
    if (this.isOwnVisit) this.$router.push(`visits/${this.visit.id}`)
  }
}
